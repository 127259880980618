.special_service{
    width:350px;
    height:auto;
    margin-left:auto;
    margin-right:auto;
    margin-top:50px;


    
}

@media screen and (max-width: 768px) {


    .special_service{
        margin-bottom:20px;
    
        
    }


}