#care_image{


margin:auto;
align-self: center;
;
    
}

#alphaeon_image{


    margin-top:10px;
    margin:auto;
    align-self: center;
}