.before_after_carousel{


}

#carouselExampleControls2{

width:60%;

}

#carouselExampleControls{
    width:60%;
}

.img-resp
{
    height:300px;
    width:auto;
    margin:auto;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}

@media only screen and (max-width: 768px) 
{


  
#carouselExampleControls2{

    width:100%;
    
    }
    
    #carouselExampleControls{
        width:100%;
    }

    .img-resp
    {
        width:100%;
        height:auto;
    }

    .carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter:none;
}
    
}

