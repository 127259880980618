.mobile{

display:none;

}

.not-mobile {
  display:block
}

.special_home{
  width:400px;
  height:auto;
  margin:20px;
}


@media screen and (max-width: 768px) {
 
  .mobile{

    display:block;
    
    }
    
    .not-mobile {
      display:none;
    }


    .special_home{
      width:80%;
      height:auto;
      margin-left:auto;
      margin-right:auto;
      margin-top:25px;
    }

}