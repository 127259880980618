#get_in_touch{
    color: #54595f;
    font-family: "Merriweather", Sans-serif;
    font-size: 35px;
    font-weight: 700;
}
.touch_title{
    color: #54595f;
    font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;

}
.touch_body {
    color: #7a7a7a;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 300;
    margin-top:20px;
}

#map{

    border:0;
    width: 100%;
    height:450px;
    
}

.cursor_pointer_onhover:hover{

    cursor: pointer;
}