#footer_container{

    width:100%;
    background-color:#3a5875;
    padding: 30px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;

}

.hidden_links {
    display:none;
}

#phone_footerzzz:hover
{
cursor: pointer;
}

#address_footerzzz:hover{

    cursor: pointer;
}