
.banner_background{
    background-color: #7a7a7a;
    background-image: url(https://www.manhattanksdental.com/wp-content/uploads/2019/07/circle-bg.jpg);
    background-position: center center;
    background-size: cover;
    text-align: center;
    padding-top:40px;
    padding-bottom:40px;
    color: #3a5875;
    font-family: "Merriweather", Sans-serif;
    font-size: 60px;
    font-weight: 500;
    line-height: 1.2em;
}
