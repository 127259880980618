#top_portion{

width:100%;
background-color:#3a5875;
padding:30px;
color:white;
font-weight:400;
font-family: Arial, Helvetica, sans-serif;
font-size: 20px;


}
#map_icon_mobile{
   
color:#3a73f9;
margin-left:30px;

}
#phone_icon_mobile{

    color:#3a732f

}

.hidden_links{

    display:none;
}

#phone_number_topz:hover{

cursor:pointer;

}
#address_topz{

    cursor:pointer;
}




.phone_number_top{
    display:inline-block !important;

}

.address_top{
    display:inline-block !important;
   margin-left:60px;
}


#phone-icon{
margin-right:7px
}

#location-icon{
    margin-right:7px;
}

@media only screen and (max-width: 1125px) 
{

    .inline-blockclass{
        display: inline-block !important; 
        font-size:30px;
     }
     
    .phone_number_top{
        display:none !important ;

    }

    #top_portion{

        display: none;
    }
    
    
    .address_top{
        display: none !important;
    }
}