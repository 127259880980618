#image_aben{


    width:70%;
}

.padding_for_container{

    padding:150px;
}




@media screen and (max-width:977px) {

    #image_aben{


        width:100%;
    }
    .padding_for_container{
        padding:0;
    }
}

@media screen and (max-width:768px) {

    #image_aben{

        margin-left:14%;
        width:70%;
    }

    .padding_for_container{
        padding:20px;
    }
   
}
