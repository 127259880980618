

.navbar_links 
{

  color:black;
  text-decoration: none;

}

@media only screen and (min-width: 768px) 
{
.navbar-nav > li{
    margin-left:50px;
    margin-right:50px;
    font-size: 20px;
  }

.item-padding{
    margin-top:20px;
    margin-bottom:20px;
}

.overflow_show{
height:50vh;
overflow-y: auto;
overflow-x:hidden;



}



  
}

@media only screen and (max-width: 1125px) 

{

  .not-mobile_brand_icon{

    display: none;
  }

  .bg-light{
    background-color:#3a5875 !important;
  
  }

  .navbar-toggler-icon{

    background-color: rgb(146, 179, 208);
  }

  .nav-link{

    color:white!important;
   
  }
}
