.services_actual_page_header{

    color: #54595f;
    font-family: "Montserrat", Sans-serif;
    line-height: 1.4em;
    width:70%;
    font-size:30px;
    font-weight:600;
   
    

}

.services_actual_page_text{
    color: #7a7a7a;
    font-family: "Montserrat", Sans-serif;
    width:70%;
    margin-top:40px;


}

.content_holder_services{

    padding:120px;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

@media screen and (max-width: 768px) {


    .services_actual_page_header{

        color: #54595f;
        font-family: "Montserrat", Sans-serif;
        line-height: 1.4em;
        width:100%;
        font-size:30px;
        font-weight:600;
       
        
    
    }
    
    .services_actual_page_text{
        color: #7a7a7a;
        font-family: "Montserrat", Sans-serif;
        width:100%;
        margin-top:40px;
    
    
    }

    .content_holder_services{

        padding:20px;
        
    }

}

