#home_banner{
    width:100%;
}

#smiling_woman_img{

    width:95%;
    border-radius: 10px;
    margin-top:55px;
    margin-left: auto;
    margin-right:auto;
}

ul.checkmarks_font {
    list-style: none;
  }
  
  ul.checkmarks_font li:before {
    content: '✓';
    font-size: 170%; /* or whatever */
    padding-right: 5px;
    color:green;
  }

  .checkmarks_font{

    font-size:30px;
  }

  #who_we_are_title{
    color: #54595f;
    font-family: "Merriweather", Sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  #who_are_text{
    text-align: center;
    color: #7a7a7a;
    font-family: "Montserrat", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 2em;
    overflow-wrap: break-word;
 
  }

  #who_are_text p {
    width:50%;
    text-align: center;
    margin:auto;
  }



/* start services */

  #services_homepage{

 
    height:auto;
    background-image: url("../../resources/services_background.png");
    text-align: center;
    padding-top:40px;
    padding-bottom:40px;
  }

  #small_title_services{


    text-align: center;
    color:white;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 300;
    text-transform: uppercase;

  }

#services_what {

  color: #ffffff;
  font-family: "Merriweather", Sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2em;
}

#services_long{

  text-align: center;
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
}

.services_home_title{
color: #ffffff;
font-family: "Montserrat", Sans-serif;
font-size: 18px;
font-weight: 600;

}

.divider{

  border-top:3px solid rgb(110,193,228);
  width:7%;
  margin-left:auto;
  margin-right:auto;
  margin-top:15px;

}

.services_home_link{

  color:white;
  text-decoration: none;

}

.services_home_link:hover{

color:white;

}





/* services end */

/* stripe portion start */

#contact_us_for_appointment
{

    background-image: url("../../resources/gray_stripes_background.png");
    height:450px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;

    
}


#text_under_one_call_away {

  text-align: center;
  color: #3a5875;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 2em;
  width:60%;
     


}

#top_portion_homepage{

margin-top:40px;


}


#title_one_call_away{
    color: #3a5875;
    font-family: "Merriweather", Sans-serif;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.2em;
    margin-top: 30px;
   
}

#phone_number_contact_us{
  text-align: center;
  color: #3a5875;
  font-family: "Montserrat", Sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 2em;
}

/* stripe portion end */
  
  
  @media screen and (max-width: 768px) {



    #top_portion_homepage{

      margin-top:-40px;
      
      
      }
    #who_are_text p {
        width:90%;
        
      }
      .margin-top-collaspe{

        margin-top:30px;
        
        }

      .checkmarks_font{
        text-align: center;
        font-size:18px;
      }

/* stripe portion start */

#contact_us_for_appointment
{

    background-image: url("../../resources/gray_stripes_background.png");
    height:450px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;

    
}


#text_under_one_call_away {


font-size: 16px;
width:90%
     


}


#title_one_call_away{
    font-size: 30px;
   
}

#phone_number_contact_us{
  
  font-size: 25px;
 
}

/* stripe portion end */



  }