#construction_image{


    width:90%;
    margin:auto;
}
#container_image{

    display:flex;
    justify-content: center;
}