.toggle_title{
    color:#54595f;
    text-decoration: none;
    font-weight: 900;
    font-family: "Montserrat", Sans-serif;
    align-self: flex-start;
    margin-left:15%;
    margin-top:20px;
}

.collaspe_body{

    align-self: flex-start;
    margin-left:15%;
    margin-top:25px !important;
}

.collaspe_cancel_margin{
    margin-top:0px ! important;
}

#how_do_they_work_button_toggle{
    margin-top:40px;
}

#implant_video{

margin-top:40px;

}


@media screen and (max-width: 768px) {

    .toggle_title{
  
        align-self:flex-start;
        margin-left:0%;
       
    }

    .collaspe_body{

        align-self: flex-start;
        margin-left:0%;
        margin-top:25px !important;
    }


}