.hidden_links 

{

    display:none;
}

#phone_number_contact_us:hover{

    cursor: pointer;
}