.small_title_services{
    color: #54595f;
    font-family: "Montserrat", Sans-serif;
    line-height: 1.4em;
    font-size:15px;
    font-weight:600;
    align-self:flex-start;
    margin-top:40px;
    margin-left:15%

}


@media screen and (max-width: 768px) {

    .small_title_services{
       
        margin-left:0%;
    
    }

}