#services_page_container > *
{
   height:auto;
   text-align: center;
   padding-top:40px;
   padding-bottom:40px;
   color:rgb(58,88,117) !important;
}

.font_color{
        color:rgb(58,88,117) !important;

}
.font_color_grey{

        color:#7a7a7a !important;
}